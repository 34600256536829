import axios from 'axios';
axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token');

export const scyllaDbApi = axios.create({
  baseURL:  process.env.REACT_APP_API_URL + '/0.2',
  headers: {
    'Authorization': 'Bearer ' + localStorage.getItem('token'),
  }

});

export default class ApiLoan {

    getResources(args={}){
        let url;
        let cancelToken;

        let params = {};
        if (args.hasOwnProperty('url')){
            url = args.url;
        }
        if (args.hasOwnProperty('page')){
            params['page'] = args.page;
        }
        if (args.hasOwnProperty('filters')){
            if (!params.hasOwnProperty('q')){
                params['q'] = {}
            }
            params['q']['filters'] = args.filters;
        }
        if (args.hasOwnProperty('order_by')){
            if (!params.hasOwnProperty('q')){
                params['q'] = {}
            }
            params['q']['order_by'] = args.order_by;
        }
        if (args.hasOwnProperty('group_by')){
            if (!params.hasOwnProperty('q')){
                params['q'] = {}
            }
            params['q']['group_by'] = args.group_by;
        }
        if (args.hasOwnProperty('results_per_page')){
            params['results_per_page'] = args.results_per_page;
        }
        if (args.hasOwnProperty('cancelToken')){
            cancelToken = args.cancelToken;
        }
        if (args.hasOwnProperty('search_query')){
            params['search_query'] = args.search_query;
        }
        if (args.hasOwnProperty('selected_user')){
            params['selected_user'] = args.selected_user;
        }

        if (args.hasOwnProperty('selected_group')){
            params['selected_group'] = args.selected_group;
        }

        if (args.hasOwnProperty('pinup_messages')){
            params['pinup_messages'] = args.pinup_messages;
        }

        if (args.hasOwnProperty('unread_messages')){
            params['unread_messages'] = args.unread_messages;
        }

        if (args.hasOwnProperty('archived')){
            params['archived'] = args.archived;
        }

        if (args.hasOwnProperty('not_assigned')){
            params['not_assigned'] = args.not_assigned;
        }

        if (args.hasOwnProperty('start_date')){
            params['start_date'] = args.start_date;
        }
        if (args.hasOwnProperty('end_date')){
            params['end_date'] = args.end_date;
        }
        if (args.hasOwnProperty('from_id')){
            params['from_id'] = args.from_id;
        }
        if (args.hasOwnProperty('validateEmail') && args.validateEmail){
            url = '/validate_email';
            params = { email: args.email };
        }

        return axios({
            method: 'get',
            url: url,
            cancelToken: cancelToken,
            params: params
        });
    }

    getResourcesGeneric(args= {}, url_version) {
        let url;
        let cancelToken;

        let params = {};
        if (args.hasOwnProperty('url')){
            url = args.url;
        }
        if (args.hasOwnProperty('page')){
            params['page'] = args.page;
        }
        if (args.hasOwnProperty('filters')){
            if (!params.hasOwnProperty('q')){
                params['q'] = {}
            }
            params['q']['filters'] = args.filters;
        }
        if (args.hasOwnProperty('order_by')){
            if (!params.hasOwnProperty('q')){
                params['q'] = {}
            }
            params['q']['order_by'] = args.order_by;
        }
        if (args.hasOwnProperty('group_by')){
            if (!params.hasOwnProperty('q')){
                params['q'] = {}
            }
            params['q']['group_by'] = args.group_by;
        }
        if (args.hasOwnProperty('results_per_page')){
            params['results_per_page'] = args.results_per_page;
        }
        if (args.hasOwnProperty('cancelToken')){
            cancelToken = args.cancelToken;
        }
        if (args.hasOwnProperty('search_query')){
            params['search_query'] = args.search_query;
        }
        if (args.hasOwnProperty('selected_user')){
            params['selected_user'] = args.selected_user;
        }

        if (args.hasOwnProperty('selected_group')){
            params['selected_group'] = args.selected_group;
        }

        if (args.hasOwnProperty('pinup_messages')){
            params['pinup_messages'] = args.pinup_messages;
        }

        if (args.hasOwnProperty('unread_messages')){
            params['unread_messages'] = args.unread_messages;
        }

        if (args.hasOwnProperty('archived')){
            params['archived'] = args.archived;
        }

        if (args.hasOwnProperty('not_assigned')){
            params['not_assigned'] = args.not_assigned;
        }

        if (args.hasOwnProperty('start_date')){
            params['start_date'] = args.start_date;
        }
        if (args.hasOwnProperty('end_date')){
            params['end_date'] = args.end_date;
        }
        if (args.hasOwnProperty('from_id')){
            params['from_id'] = args.from_id;
        }
        if (args.hasOwnProperty('validateEmail') && args.validateEmail){
            url = '/validate_email';
            params = { email: args.email };
        }

        const api = url_version === '0.1' ? axios : scyllaDbApi;

        return api({
            method: 'get',
            url: url,
            cancelToken: cancelToken,
            params: params
        });
    }

    getResource(args={}){
        let url = '';
        let resource_id = '';
        if (args.hasOwnProperty('url')){
            url = args.url;
        }
        if (args.hasOwnProperty('resource_id')){
            resource_id = args.resource_id;
        }
        if (resource_id){
            return axios.get(url + resource_id)
        } else {
            return axios.get(url)
        }

    }

    getResourceBlob(args={}){
        let url = '';
        if (args.hasOwnProperty('url')){
            url = args.url;
        }
        let params = {};

        if (args.hasOwnProperty('min_date')){
            params['min_date'] = args.min_date;
        }
        if (args.hasOwnProperty('max_date')){
            params['max_date'] = args.max_date;
        }
        if (args.hasOwnProperty('gateway_id')){
            params['gateway_id'] = args.gateway_id;
        }
        return axios({
            method: 'get',
            url: url,
            params: params,
            responseType: 'blob'
        });
    }


    postResource(args={}){
        let url = '';
        let formData = {};

        let params = {};

        if (args.hasOwnProperty('url')){
            url = args.url;
        }
        if (args.hasOwnProperty('data')){
            formData = args.data;
        }

        if (args.hasOwnProperty('start_date')){
            params['start_date'] = args.start_date;
        }
        if (args.hasOwnProperty('end_date')){
            params['end_date'] = args.end_date;
        }

        return axios({
            method: 'post',
            url: url,
            data: formData,
            params: params
        });
    }

    postResourceGeneric(args={}, url_version){
        let url = '';
        let formData = {};

        let params = {};

        if (args.hasOwnProperty('url')){
            url = args.url;
        }
        if (args.hasOwnProperty('data')){
            formData = args.data;
        }

        if (args.hasOwnProperty('start_date')){
            params['start_date'] = args.start_date;
        }
        if (args.hasOwnProperty('end_date')){
            params['end_date'] = args.end_date;
        }

        const api = url_version === '0.1' ? axios : scyllaDbApi;

        return api({
            method: 'post',
            url: url,
            data: formData,
            params: params
        });

    }

    postResourceBlob(args={}){
        let url = '';
        let formData = {};

        if (args.hasOwnProperty('url')){
            url = args.url;
        }
        if (args.hasOwnProperty('data')){
            formData = args.data;
        }

        const headers = {
            'Content-Type': args.blob.type
        }

        return axios({
            method: 'post',
            url: url,
            data: formData,
            headers: headers
        });
    }

    patchResource(args={}){
        let url = '';
        let uri = '';
        let formData = {};
        if (args.hasOwnProperty('url') && args.hasOwnProperty('resource_id')){
            let get_slash_in_url = args.url.slice(-1);

            if (get_slash_in_url === '/') {
                uri = args.url.slice(0,-1)
            } else {
                uri = args.url;
            }
            url = uri + '/' + args.resource_id;
        } else {
            url = args.url;
        }

        if (args.hasOwnProperty('data')){
            formData = args.data;
        }

        return axios({
            method: 'patch',
            url: url,
            data: formData
        });

    }

    patchResourceGeneric(args={}, url_version){
        let url = '';
        let uri = '';
        let formData = {};
        if (args.hasOwnProperty('url') && args.hasOwnProperty('resource_id')){
            let get_slash_in_url = args.url.slice(-1);

            if (get_slash_in_url === '/') {
                uri = args.url.slice(0,-1)
            } else {
                uri = args.url;
            }
            url = uri + '/' + args.resource_id;
        } else {
            url = args.url;
        }

        if (args.hasOwnProperty('data')){
            formData = args.data;
        }

        const api = url_version === '0.1' ? axios : scyllaDbApi;

        return api({
            method: 'patch',
            url: url,
            data: formData
        });
    }


    deleteResource(args={}){
        let url = '';
        let resource_id = '';

        if (args.hasOwnProperty('url')){
            url = args.url;
        }

        if (args.hasOwnProperty('resource_id')){
            resource_id = args.resource_id;
        }

        if (resource_id){
            return axios.delete(url + '/' + resource_id)
        }
    }

    deleteResourceGeneric(args={}, url_version){
        let url = '';
        let resource_id = '';

        if (args.hasOwnProperty('url')){
            url = args.url;
        }

        if (args.hasOwnProperty('resource_id')){
            resource_id = args.resource_id;
        }

        if (resource_id){
            const api = url_version === '0.1' ? axios : scyllaDbApi;
            return api.delete(url + '/' + resource_id)
        }

    }

}