import React, {Fragment, useMemo} from 'react';
import {getFormattedDate} from "../../../utils/utils";

import './ChatListItem.css';

export default function ChatListItem(props) {
    const {text, client,  created, id} = props.data;
    const fulltime = getFormattedDate(created);


    const Fulltime = () => useMemo(() => {
        return (
            <div className='full-time p-as-center'>
                {fulltime}
            </div>
        )
    }, [id])


    const renderAction = () => {
        return (
            <div className="grid">
                <div className="conversation-title col-8 md-9">
                    <h1 className="conversation-list-item-name">
                        {client.name}
                    </h1>
                </div>
                <div className="col-4 md-3 p-nogutter">
                    <div className="flex justify-content-end p-nogutter">
                        {Fulltime()}
                    </div>
                </div>
            </div>
        );
    };

    const active_element = props.conversationActive ? 'active' : '';


    return (
        <Fragment>
            <div className={`conversation-list-item ${active_element}`} onClick={props.onClick}>
                <div className="conversation-info">
                    {renderAction()}
                    <div className="grid">
                        <div className="col-8 md-9">
                            <div className="conversation-snippet" >
                                <div className="p-conversation-preview">
                                    <p>{text}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}